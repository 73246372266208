import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import bookingConfirmation from "../../assets/images/booking-confirmation.svg";
import modifyObject from "helper/modifyObject";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "store";
import { getAuthInfo } from "selectors/auth";
import moment from "moment";
import { Seminar } from "types/seminar";
import { useBookSeminarMutation } from "store/reducers/seminar";

export interface PropType {
  seminarDetails: Seminar;
}

const ConfirmBooking = ({ seminarDetails }: PropType) => {
  const { NumberOfPlaces, NumberOfBooked = "0" } = seminarDetails;
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const auth = useSelector((state) => getAuthInfo(state));

  const [bookSeminar, { data, isError, isSuccess, isLoading, error }] =
    useBookSeminarMutation();

  const numberOfPlaces = parseInt(NumberOfPlaces);
  const numberOfBooked = NumberOfBooked ? parseInt(NumberOfBooked) : 0;
  const availablePlaces = numberOfPlaces - numberOfBooked;

  useEffect(() => {
    if (isError || isSuccess) {
      enqueueSnackbar(
        (error as string | (string & any[])) || (data?.message as string),
        {
          variant: isSuccess ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
      );
    }
    if (isSuccess) {
      handleCloseDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.message, error, isError, isSuccess]);

  const initialValues = {
    IsFIveYearToRetire: false,
    IsAttended: "",
    Presentations: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      const saveValues = {
        OrganizationID: auth.user?.EmployerID,
        SeminarID: seminarDetails?.ID ?? "",
      };
      await bookSeminar(modifyObject(saveValues));
    },
  });

  const { handleSubmit } = formik;

  return (
    <>
      <Tooltip
        title={"There are no avaliable place for booking"}
        disableHoverListener={Boolean(availablePlaces)}
      >
        <span>
          <Button
            disabled={!availablePlaces}
            className="btn btn-purple btn-sm"
            onClick={handleOpenDialog}
          >
            <span>Book Now</span>
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        className="custom-dialog main-wrapper"
      >
        <DialogTitle className="custom-dialog-title p-0 d-flex justify-content-between">
          <span>Booking Confirmation</span>
          <Button className="dialog-close p-0" onClick={handleCloseDialog}>
            <i className="mmm-cancel"></i>
          </Button>
        </DialogTitle>
        <Box className="custom-form">
          <Box className="booking-confirmation_content">
            <Box className="booking-confirmation_image text-center">
              <img
                src={bookingConfirmation}
                className="img-fluid dark-logo"
                alt=""
              />
            </Box>
            <Typography
              variant="body1"
              sx={{ lineHeight: "24px !important" }}
              className="text-center"
            >
              You are about to book
              <b> {seminarDetails.Title}</b>
              <br className="d-none d-sm-block" /> on
              <b>
                {" "}
                {moment(seminarDetails?.StartDate).format(
                  "DD-MM-YYYY"
                )}{" "}
                At {moment(seminarDetails?.StartTime).format("LT")}.
              </b>
            </Typography>
            <Typography
              className="note text-center"
              sx={{ mt: 3 }}
              variant="body2"
            >
              If this is the right seminar for you. please '
              <b>Confirm your Booking</b>'
              <br className="d-none d-sm-block" /> below.
            </Typography>
          </Box>
          <div className="form-block action-btns d-flex align-items-center justify-content-between">
            <Button className="btn btn-link btn-md" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary btn-md"
              onClick={() => handleSubmit()}
              type="submit"
              disabled={isLoading}
            >
              Confirm Booking
            </Button>
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmBooking;
