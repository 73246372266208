import { useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Grid,
  Button,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import { isEmpty } from "lodash";
import NoDataFound from "components/NoDataFound";
import displayFormatedDate from "helper/displayFormatedDate";
import LottieLoader from "components/LottieLoader";
import { useSelector } from "store";
import { getAuthInfo } from "selectors/auth";
import { FilterParam } from "types/seminar";
import { Filter } from "sections/seminars/filter";
import { useGetAllSeminarQuery } from "store/reducers/seminar";
import SeminarBanner from "components/SeminarBanner";

const SeminarsList = () => {
  // const params = useParams();
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const auth = useSelector((state) => getAuthInfo(state));
  const [pageNo, setPageNo] = useState(1);
  const [filterProperties, setFilterProperties] = useState<FilterParam[]>([]);

  useEffect(() => {
    localStorage.removeItem("baseURl");
  }, []);

  const filter = useMemo(
    () =>
      filterProperties.map((f) =>
        f.columnType === "EventDate"
          ? {
              ...f,
              value1: f.value1 ? moment(f.value1).format("YYYY-MM-DD") : null,
              value2: f.value2 ? moment(f.value2).format("YYYY-MM-DD") : null,
            }
          : f
      ),
    [filterProperties]
  );

  const displayDate = useMemo(() => {
    const date = filter.find((f) => f.columnType === "EventDate");
    if (isEmpty(date)) return null;
    return {
      startDate: displayFormatedDate(date?.value1),
      endDate: displayFormatedDate(date?.value2),
    };
  }, [filter]);

  const {
    data: responseBody,
    isLoading,
    isFetching,
  } = useGetAllSeminarQuery(
    {
      pageNo,
      size: 6,
      filter,
      EmployerID: auth.user?.EmployerID ?? "",
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data = [], totalRecords = 0 } = responseBody ?? {};

  const handleFilter = (value: FilterParam[]) => {
    setOpen(false);
    setFilterProperties(value);
  };

  const handleLoadProperties = () => {
    if (!isLoading && data.length < totalRecords) {
      const currentPage = pageNo + 1;
      setPageNo(currentPage);
    }
  };

  return (
    <>
      <Box className="product-list-section section pt-0 px-0">
        <SeminarBanner />
        <Box className="app-title_section">
          <Container className="custom-container">
            <Grid container className="custom-row justify-content-center">
              <Grid xs={6} className="custom-col">
                <Box className="app-title_wrapper d-flex align-items-center flex-wrap flex-lg-nowrap justify-content-end">
                  <div className="app-title_center d-flex align-items-center justify-content-end flex-wrap flex-lg-nowrap">
                    {displayDate && (
                      <Box className="category-pills">
                        <List>
                          <ListItem>
                            {moment(displayDate?.startDate).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            -{" "}
                            {moment(displayDate?.endDate).format("DD-MM-YYYY")}
                            <Button
                              variant="text"
                              onClick={() =>
                                setFilterProperties(
                                  filter.filter(
                                    (res) => res.columnType !== "EventDate"
                                  )
                                )
                              }
                            >
                              <i className="mmm-cancel"></i>
                            </Button>
                          </ListItem>
                        </List>
                      </Box>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className="event-list_section">
          {isLoading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <LottieLoader />
            </Backdrop>
          ) : (
            <Container className="custom-container">
              {data.length > 0 ? (
                <Grid
                  container
                  className="custom-row event-list-row justify-content-center"
                >
                  {data?.map((res) => (
                    <Grid
                      xs={12}
                      sm={6}
                      lg={4}
                      className="custom-col event-list_col"
                      key={res.ID}
                    >
                      <Card
                        className={
                          // modified-card
                          "custom-card event-card"
                        }
                      >
                        <CardContent className="card-content p-0">
                          {res.IsBooked && (
                            <Tooltip
                              title="Booked Seminar"
                              placement="bottom"
                              arrow
                            >
                              <Button className="tooltip-btn booked-tooltip">
                                <i className="mmm-tick"></i>
                              </Button>
                            </Tooltip>
                          )}
                          {/* <Typography
                            variant="h2"
                            className="text-start dark-purple font-25"
                          >
                            {params?.themeName ?? ""}
                          </Typography> */}
                          <Typography
                            variant="h3"
                            className="mt-2 text-start medium-bold font-22"
                          >
                            {res.Title}
                          </Typography>
                          {res.Building ? (
                            <Typography
                              variant="h4"
                              className="mt-2 text-start purple-text regular-bold font-16"
                            >
                              Building: {res.Building}
                            </Typography>
                          ) : null}
                        </CardContent>
                        <CardActions
                          className="card-action mt-4 p-0 pt-2 pt-md-3 justify-content-between"
                          disableSpacing
                        >
                          <Button
                            className="btn btn-purple btn-md font-16"
                            onClick={() =>
                              navigate(`/employee/seminars/list/${res.ID}`)
                            }
                          >
                            Check Availability
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                  {data.length < totalRecords && (
                    <Grid
                      xs={12}
                      className="custom-col event-list_action-col pt-4 mt-2 mt-md-3"
                    >
                      <LoadingButton
                        className="btn btn-secondary btn-md mx-auto"
                        variant="contained"
                        type="button"
                        loading={isFetching}
                        onClick={handleLoadProperties}
                      >
                        Load more
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <NoDataFound />
              )}
            </Container>
          )}
        </Box>
      </Box>

      <Filter
        open={open}
        handleClose={handleClose}
        filters={filterProperties ?? []}
        handleFilter={handleFilter}
      />
    </>
  );
};

export default SeminarsList;
