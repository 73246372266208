import { Box, Pagination, Typography, Button } from "@mui/material";
import ConfirmBooking from "./ConfirmBooking";
import { useFilter } from "hooks/useFilter";
import moment from "moment";
import { useParams } from "react-router";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
// import NoDataFound from "components/NoDataFound";
import { useSelector } from "store";
import CancelBooking from "./CancelBooking";
import { useCancelBookingSeminarMutation, useGetCheckAvliablityQuery } from "store/reducers/seminar";
import { Seminar } from "types/seminar";

const SeminarDetailView = () => {
  const { id: seminarID } = useParams();
  const { filter, setsearchParams } = useFilter();
  const pageIndex = filter("page") ? parseInt(filter("page")) : 1;
  const size = filter("pageSize") ? parseInt(filter("pageSize")) : 30;
  const { user } = useSelector((store) => store.auth);

  const {
    data: responseBody,
  } = useGetCheckAvliablityQuery({
    pageNo: pageIndex,
    size,
    seminarID,
    employerID: user?.EmployerID ?? "",
  });

  const { data = [], totalRecords = 0, pageSize = 0 } = responseBody ?? {};
  const count = Math.ceil(totalRecords / pageSize);
  const [cancelSeminar, { data: joinBookingData, isError, isSuccess, error }] =
    useCancelBookingSeminarMutation();

  useEffect(() => {
    localStorage.removeItem("baseURl");
  }, []);

  useEffect(() => {
    if (isError || isSuccess) {
      enqueueSnackbar(
        (error as string | (string & any[])) ||
          (joinBookingData?.message as string),
        {
          variant: isSuccess ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinBookingData?.message, error, isError, isSuccess]);

  const onChangePageIndex = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setsearchParams("page", value.toString());
  };

  const onJoinBooking = async (seminar: Seminar) => {
    const { IsBooked, attendeeID } = seminar;
    if (IsBooked && attendeeID) {
      const saveValues = {
        ID: attendeeID,
        IsBooked: true,
      };
      await cancelSeminar(saveValues);
    }
  };

  return (
    <>
      <Box className="retirement-block d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
        <Box className="retirement-block_content" sx={{ p: 3 }}>
          <Typography variant="h2">{data[0]?.Title}</Typography>
          <Typography variant="body1" className="font-20 medium-bold">
            {data[0]?.Description}
          </Typography>
        </Box>
      </Box>
      <Box className="event-detail_list">
        {data?.map((res) => {
          const {
            NumberOfBooked = "0",
            NumberOfPlaces,
            StartTime,
            EndTime,
            StartDate,
            ID,
            IsBooked,
            Location,
            Building,
            RoomNumber,
          } = res;
          const startDate = moment(StartDate);
          const startTime = moment(StartTime);
          const endTime = moment(EndTime);
          const duration = endTime.diff(startTime, "minutes");
          const hours = Math.floor(duration / 60);
          const minutes = duration % 60;
          const formNow = startTime.diff(moment(), "minutes");
          const numberOfPlaces = parseInt(NumberOfPlaces);
          const numberOfBooked = NumberOfBooked ? parseInt(NumberOfBooked) : 0;
          const availablePlaces = numberOfPlaces - numberOfBooked;
          return (
            <Box className="event-detail_block booking_block" key={ID}>
              <Box className="event-detail_card d-flex flex-wrap flex-lg-nowrap">
                <Box className="event-card_info">
                  <Typography variant="h3">Date & Time</Typography>
                  <Typography variant="body1">
                    {moment(startDate).format("DD-MM-YYYY")} |{" "}
                    {startTime.format("LT")}
                  </Typography>
                </Box>
                <Box className="event-card_info">
                  <Typography variant="h3">Spaces left</Typography>
                  <Typography variant="body1">
                    {availablePlaces} Places
                  </Typography>
                </Box>
                <Box className="event-card_info">
                  <Typography variant="h3">duration</Typography>
                  <Typography variant="body1">
                    {Boolean(hours) && `${hours} hour `}
                    {Boolean(minutes) && `${minutes} mins`}
                  </Typography>
                </Box>
                <Box className="event-card_info">
                  <Typography variant="h3">Location</Typography>
                  <Typography variant="body1">{Location}</Typography>
                </Box>

                {Building ? (
                  <Box className="event-card_info">
                    <Typography variant="h3">Building</Typography>
                    <Typography variant="body1">{Building}</Typography>
                  </Box>
                ) : null}

                {RoomNumber ? (
                  <Box className="event-card_info">
                    <Typography variant="h3">Room Name</Typography>
                    <Typography variant="body1">{RoomNumber}</Typography>
                  </Box>
                ) : null}

                <Box className="event-card_action-tags ms-lg-auto d-flex flex-wrap align-items-center">
                  {/* {!IsBooked && availablePlaces < 10 && numberOfPlaces !== numberOfBooked && (
                    <Box className="event-card_tag-wrapper">
                      <Box className="event-card_tag d-flex align-items-center">
                        <Box className="triangle"></Box>
                        <span>Booking Fast</span> <i className="mmm-tag"></i>
                      </Box>
                    </Box>
                  )} */}
                  {IsBooked ? (
                    <Box className="event-card_action ms-lg-auto d-flex flex-column justify-content-center">
                      {formNow <= 10 ? (
                        <Button
                          className="btn btn-purple btn-sm"
                          onClick={() => onJoinBooking(res)}
                        >
                          <span>Join Now</span>
                        </Button>
                      ) : (
                        <CancelBooking seminarID={res?.attendeeID} />
                      )}
                    </Box>
                  ) : (
                    <Box className="event-card_action ms-lg-auto d-flex flex-column justify-content-center">
                      {numberOfPlaces === numberOfBooked ? (
                        <Button className="btn btn-gray btn-sm" disabled>
                          <span>Booking Full</span>
                        </Button>
                      ) : (
                        <ConfirmBooking seminarDetails={res} />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {count > 1 && (
        <Pagination
          className="custom-pagination"
          count={count}
          onChange={onChangePageIndex}
        />
      )}
    </>
  );
};

export default SeminarDetailView;
